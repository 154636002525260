<template>
  <section>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="filterAuthors">
          <div class="row">
            <div class="col-lg-2">
              <app-select
                v-model="filter.defaultSite"
                :options="sites"
                label="Default site"
                id="filter_defaultSite"
              >
              </app-select>
            </div>
            <div class="col-lg-3 col-md-6">
              <app-input v-model="filter.name" id="filter_name" label="Name"></app-input>
            </div>
            <div class="col-lg-3 col-md-6">
              <app-input v-model="filter.originName" id="filter_originName" label="Origin name"></app-input>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-input v-model="filter.id" id="filter_id" label="Id"></app-input>
            </div>
            <div class="col-lg-2">
              <button
                type="button"
                class="btn btn-success m-t-30"
                data-test="search"
                @click="filterAuthors"
              >
                {{ $t('buttons.search') }}
              </button>
              <button
                type="button"
                class="btn btn-secondary m-t-30"
                data-test="reset_filter"
                @click="resetFilter"
              >
                {{ $t('buttons.reset_filter') }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>
<script>
import Input from '../form/inputs/Input'
import Select from '../form/select/Select'
import AuthorFilter from '../../model/AuthorFilter'

export default {
  name: 'AuthorFilter',
  data () {
    return {
      isLoading: false,
      filter: this._.cloneDeep(AuthorFilter)
    }
  },
  computed: {
    sites () {
      return this.enabledSites()
    }
  },
  components: {
    appInput: Input,
    appSelect: Select
  },
  methods: {
    filterAuthors () {
      this.$store.commit('author/setPage', 1)
      this.$store.commit('author/setFilter', this.filter)
      this.$store.dispatch('author/fetch')
    },
    resetFilter () {
      this.filter = this._.cloneDeep(AuthorFilter)
      this.$store.commit('author/resetFilter')
      this.$store.dispatch('author/fetch')
    },
    enabledSites () {
      const sites = this.$store.getters['site/all']
      return sites.filter(site => site.enabled)
    }
  },
  created () {
    this.filter = this.$store.getters['author/filter']
  }
}
</script>

<style lang="scss">

</style>
